import React, { useState } from "react";
import Modal from "react-modal";
import Services2 from "./Services2";

Modal.setAppElement("#root");

const ServiceAnimation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }

  return (
    <div className="service_list">
      <ul>
        <li>
          <div
            className="list_inner"
            onClick={toggleModalOne}
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <img className="svg" src="img/svg/1.svg" alt="" />
            <div className="service_title">
              <h3>Website</h3>
            </div>
            <div className="learn_more">
              Learn More<span></span>
            </div>
          </div>

          {/* Start Modal Motion Graphy */}
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModalOne}
            contentLabel="My dialog"
            className="custom-modal"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div className="beny_tm_modalbox_service">
              <button className="close-modal" onClick={toggleModalOne}>
                <img src="/img/svg/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner">
                <div className="description_wrap scrollable">
                  <div className="popup_informations">
                    <img
                      className="modal-web-img"
                      src="img/thumbs/web.png"
                      alt=""
                    />
                    <div className="description">
                      <h3>Websites</h3>

                      <div className="service-div-1">
                        <a
                          target="blank"
                          href="https://www.fiverr.com/ashishkhanna166/develop-reactjs-firebase-website-or-using-tailwind-css"
                        >
                          <button className="btn-web">Know More</button>
                        </a>

                        <a
                          target="blank"
                          href="https://wa.me/+918968003769?text=Hi,My name is [Your Name], and I'm reaching out to inquire about the packages you offer for website and mobile app development.?"
                        >
                          <button className="btn-web2">Contact Me</button>
                        </a>
                      </div>

                      <p>
                        I'm offering the services of creating a dynamic app in
                        React JS with backend/ API integrations. I have 2+ years
                        of experience in Javascript/ React Js development.
                        During my experience, I have worked on many React Js
                        project. Following are the services I'm offering in this
                        gig: React JS App development (Classes and Functional
                        based components). PSD/ XD/ Zeplin/ Figma to React JS
                        application. Backend/ APIs integration. Backend
                        development in Node Js/ mySQL/ MongoDB. Third Party
                        APIs/ GraphQL/ Firebase/ JSON. Material UI/ Ant design/
                        Bootstrap 5 Responsive App/ Mobile Friendly/ Animations/
                        Lazy loading Integrate External Libraries. and much
                        more.. Customers satisfaction is my first priority, I
                        always try my best to provide best services of mine to
                        my customer. NOTE: Please contact me before placing the
                        order to know my availability. <br /> Thanks, Kind
                        Regards, <br /> Ashish
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End box inner */}
            </div>
            {/* End modal box news */}
          </Modal>
          {/* End Modal Motion Graphy */}
        </li>

        <li>
          <div
            className="list_inner"
            onClick={toggleModalThree}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <img className="svg" src="img/svg/3.svg" alt="" />
            <div className="service_title">
              <h3>Mobile Apps</h3>
            </div>
            <div className="learn_more">
              Learn More<span></span>
            </div>
          </div>

          {/* Start Modal Mobile App Design */}
          <Modal
            isOpen={isOpen3}
            onRequestClose={toggleModalThree}
            contentLabel="My dialog"
            className="custom-modal"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <div className="beny_tm_modalbox_service">
              <button className="close-modal" onClick={toggleModalThree}>
                <img src="/img/svg/cancel.svg" alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner">
                <div className="description_wrap scrollable">
                  <div className="popup_informations">
                    <img
                      className="modal-web-img"
                      src="img/thumbs/app.jpeg"
                      alt=""
                    />

                    <div className="description">
                      <h3>Mobile App Development</h3>

                      <div className="service-div-1">
                        <a
                          target="blank"
                          href="https://www.fiverr.com/ashishkhanna166/develop-ios-and-android-mobile-app-using-react-native"
                        >
                          <button className="btn-web">Know More</button>
                        </a>

                        <a
                          target="blank"
                          href="https://drive.google.com/file/d/1AuuSvAdBt9rVhDBcAQ8ejvsjqMKA2yJm/view"
                        >
                          <button className="btn-web">Download Apk</button>
                        </a>

                        <a
                          target="blank"
                          href="https://wa.me/+918968003769?text=Hi,My name is [Your Name], and I'm reaching out to inquire about the packages you offer for website and mobile app development.?"
                        >
                          <button className="btn-web2">Contact Me</button>
                        </a>
                      </div>

                      <p>
                        iOS and Android mobile app development. Professionally
                        known to create user friendly mobile apps That allows
                        users to spend most of their time on your beneficial
                        application
                        <br />
                        Our expertise start from
                        <br />
                        UI based application, Map Based Application, Chat
                        Application, Social Network Application, Audio/Video
                        Application, Fitness Application
                        <br />
                        To almost every other applications That you might need
                        help with. Because I want to make sure that You are not
                        on a sole mission without assistance I'm also re-develop
                        old applications and fix problems in them to ensure your
                        users the best screen time
                        <br />
                        I'm believe that Conveying one's vision is a matter of
                        words But portraying one's concept is a battle on its
                        own. And if you've been battling alone let us help you
                        out.
                        <br />
                        Choose the right deal for you and let's get the codes
                        cracking.
                        <br />
                        I'm at your service.
                        <br />
                        Contact me for further queries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End box inner */}
            </div>
            {/* End modal box news */}
          </Modal>
          {/* End Modal Mobile App Design*/}
        </li>

        <li>
          <a
            target="blank"
            href="https://wa.me/+918968003769?text=Hi,My name is [Your Name], and I'm reaching out to inquire about the packages you offer for website and mobile app development.?"
          >
            <div
              className="list_inner"
              onClick={toggleModalFour}
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              <img className="svg" src="img/svg/4.svg" alt="" />
              <div className="service_title">
                <h3>Ui/Ux Consultancy</h3>
              </div>
              <div className="learn_more">
                Learn More<span></span>
              </div>
            </div>
          </a>
        </li>
      </ul>


      <Services2/>
    </div>
  );
};

export default ServiceAnimation;
