import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const CreativePortfolio = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  return (
    <div className="portfolio_list">
      <ul className="gallery_zoom">
        <>
          <a
            target="blank"
            href="https://www.fiverr.com/ashishkhanna166/develop-ios-and-android-mobile-app-using-react-native"
          >
            {" "}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.fiverr.com/ashishkhanna166/develop-ios-and-android-mobile-app-using-react-native"
                >
                  <h3>E-Commerce Clothing App</h3>
                  <span>Mobile App</span>
                </a>

                <Item
                  original="img/portfolio/1.jpg"
                  thumbnail="img/portfolio/1.jpg"
                  width={383}
                  height={430}
                >
                  {({ ref, open }) => (
                    <div className="gallery-link">
                      <img
                        className="project-img"
                        src="img/thumbs/app.jpeg"
                        alt="Shoot"
                        role="button"
                        ref={ref}
                        onClick={open}
                      />
                    </div>
                  )}
                </Item>
              </div>
            </li>
          </a>
          <a
            target="blank"
            href="https://www.fiverr.com/ashishkhanna166/develop-reactjs-firebase-website-or-using-tailwind-css"
          >
            {" "}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.fiverr.com/ashishkhanna166/develop-reactjs-firebase-website-or-using-tailwind-css"
                >
                  <h3>E-Commerce Clothing Website</h3>
                  <span>Website</span>
                </a>

                <Item
                  original="img/portfolio/1.jpg"
                  thumbnail="img/portfolio/1.jpg"
                  width={383}
                  height={430}
                >
                  {({ ref, open }) => (
                    <div className="gallery-link">
                      <img
                        className="project-img"
                        src="img/thumbs/web.png"
                        alt="Shoot"
                        role="button"
                        ref={ref}
                        onClick={open}
                      />
                    </div>
                  )}
                </Item>
              </div>
            </li>
          </a>
          <a target="blank" href="https://manpreetnijjar.com/">
            {" "}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.fiverr.com/ashishkhanna166/develop-static-dynamic-websites"
                >
                  <h3>Fitness Coaching Portfolio</h3>
                  <span>Website</span>

                </a>

                <Item
                  original="img/portfolio/1.jpg"
                  thumbnail="img/portfolio/1.jpg"
                  width={383}
                  height={430}
                >
                  {({ ref, open }) => (
                    <div className="gallery-link">
                      <img
                        className="project-img"
                        src="img/thumbs/nijjar.png"
                        alt="Shoot"
                        role="button"
                        ref={ref}
                        onClick={open}
                      />
                    </div>
                  )}
                </Item>
              </div>
            </li>
          </a>
          <a target="blank" href="https://ruhtyagi.com/">
            {" "}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://ruhtyagi.com/"
                >
                  <h3>Fitness Coaching Portfolio</h3>
                    
                  <span>Website</span>

                </a>

                <Item
                  original="img/portfolio/1.jpg"
                  thumbnail="img/portfolio/1.jpg"
                  width={383}
                  height={430}
                >
                  {({ ref, open }) => (
                    <div className="gallery-link">
                      <img
                        className="project-img"
                        src="img/thumbs/tyagi.png"
                        alt="Shoot"
                        role="button"
                        ref={ref}
                        onClick={open}
                      />
                    </div>
                  )}
                </Item>
              </div>
            </li>
          </a>
          <a target="blank" href="https://theshapeme.com/">
            {" "}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://theshapeme.com/"
                >
                  <h3>E-Commerce Fitness Coaching</h3>
                    
                  <span>Website</span>

                </a>

                <Item
                  original="img/portfolio/1.jpg"
                  thumbnail="img/portfolio/1.jpg"
                  width={383}
                  height={430}
                >
                  {({ ref, open }) => (
                    <div className="gallery-link">
                      <img
                        className="project-img"
                        src="img/thumbs/sid.png"
                        alt="Shoot"
                        role="button"
                        ref={ref}
                        onClick={open}
                      />
                    </div>
                  )}
                </Item>
              </div>
            </li>
          </a>
          <a target="blank" href="https://venusholidaymart.com/">
            {" "}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://venusholidaymart.com/"
                >
                  <h3>Travel and Tourism</h3>
                    
                  <span>Website</span>

                </a>

                <Item
                  original="img/portfolio/1.jpg"
                  thumbnail="img/portfolio/1.jpg"
                  width={383}
                  height={430}
                >
                  {({ ref, open }) => (
                    <div className="gallery-link">
                      <img
                        className="project-img"
                        src="img/thumbs/venus3.png"
                        alt="Shoot"
                        role="button"
                        ref={ref}
                        onClick={open}
                      />
                    </div>
                  )}
                </Item>
              </div>
            </li>
          </a>
          <a target="blank" href="https://onepercentclubaw.com/">
            {" "}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://onepercentclubaw.com/"
                >
                  <h3>Online Clothing Store</h3>
                    
                  <span>Website</span>

                </a>

                <Item
                  original="img/portfolio/1.jpg"
                  thumbnail="img/portfolio/1.jpg"
                  width={383}
                  height={430}
                >
                  {({ ref, open }) => (
                    <div className="gallery-link">
                      <img
                        className="project-img"
                        src="img/thumbs/one.png"
                        alt="Shoot"
                        role="button"
                        ref={ref}
                        onClick={open}
                      />
                    </div>
                  )}
                </Item>
              </div>
            </li>
          </a>
        </>
      </ul>
      {/* END TABLIST */}
    </div>
  );
};

export default CreativePortfolio;
