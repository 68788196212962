import React from "react";
import ReactWhatsapp from "react-whatsapp";

const Services2 = () => {
  const message = `
  Hi,My name is [Your Name], and I'm reaching out to inquire about the packages you offer for website and mobile app development.?
  `;

  const content = [
    {
      id: 1,
      title: "Unlimited creation",
      description:
        " Create a Website and Mobile App with a complete suite of advanced functionalities and bring your vision to life.",
    },
    {
      id: 2,
      title: "Powerful infrastructure",
      description:
        "Get an enterprise-grade foundation, engineered for your limitless scalability and peace of mind.",
    },
    {
      id: 3,
      title: "The place for growth",
      description:
        "Convert and scale seamlessly with built-in marketing and business solutions.",
    },
  ];

  return (
    <div className="service-1">
      <div className="service-2">
        <div className="beny_tm_title_holder">
          <h2>
            One platform, <br />
            infinite possibilities
          </h2>
        </div>

        {/* <div className="service2-4">
            <h1 className="">Website templates that set <br /> you up for success</h1>
        </div> */}

        <div className="service2-1">
          {content.map((item, index) => (
            <div
              key={index}
              className="service2-2"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <h1>{item.title}</h1>
              <p>{item.description}</p>
            </div>
          ))}
        </div>

        <div>
          <ReactWhatsapp
            className="service-2-btn1"
            number={"+91 8968003769"}
            message={message}
          >
            Get started
          </ReactWhatsapp>
        </div>
      </div>
    </div>
  );
};

export default Services2;
