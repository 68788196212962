import React from "react";
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import Currency from "react-currency-formatter";

import ReactWhatsapp from "react-whatsapp";
const Marketing = ({ select }) => {
  const plansWeb = [
    {
      id: 1,
      title: "Basic",
      title2: "Basic Plan",
      price: 15000,
      price2: 200,
      point1: "Initial Review & Analysis ",
      point2: "Keyword Research Upto 6",
      point3: "Website Analysis ",
      point4: "Competitor Analysis ",
      point5: "On-Page Optimization ",
      point6: "Meta Tag Optimization ",
      point7: "Alt & Title Tag Optimization",
      point8: "Backlink Analysis",
      point9: "Backlinks Creation 7",
      point10: "SEO Friendly Content Check",
      point11: "Social Media Marketing ",
      point12: "Content Creation Upto 3 Blogs ",
      point13: "Third Party Content Creation",
      point14: "Email Marketing Upto 3 Campaigns ",
      point15: "Paid Advertising ",
      point16: "Social Media Planning ",
      point17: "Social Media Content Creation ",
      message: `Hi, My [Your Name] I'm interested in the Basic Marketing ${select} Plan. Could we schedule a meeting? Please let me know your availability for a call. `,
      active1: true,
      active2: true,
      active3: true,
      active4: true,
      active5: false,
      active6: true,
      active7: false,
      active8: true,
      active9: true,
      active10: true,
      active11: false,
      active12: true,
      active13: false,
      active14: true,
      active15: true,
      active16: false,
      active17: false,
    },
    {
      id: 2,
      title: "Advanced",
      title2: "Advanced Plan",
      price: 35000,
      price2: 450,

      point1: "Initial Review & Analysis ",
      point2: "Keyword Research Upto 10",
      point3: "Website Analysis ",
      point4: "Competitor Analysis ",
      point5: "On-Page Optimization ",
      point6: "Meta Tag Optimization ",
      point7: "Alt & Title Tag Optimization",
      point8: "Backlink Analysis",
      point9: "Backlinks Creation 25",
      point10: "SEO Friendly Content Check",
      point11: "Social Media Marketing ",
      point12: "Content Creation Upto 6 Blogs ",
      point13: "Third Party Content Creation",
      point14: "Email Marketing Upto 6 Campaigns ",
      point15: "Paid Advertising ",
      point16: "Social Media Planning ",
      point17: "Social Media Content Creation ",
      message: `Hi, My [Your Name] I'm interested in the Advanced Marketing ${select} Plan. Could we schedule a meeting? Please let me know your availability for a call. `,
      active1: true,
      active2: true,
      active3: true,
      active4: true,
      active5: true,
      active6: true,
      active7: true,
      active8: true,
      active9: true,
      active10: true,
      active11: true,
      active12: true,
      active13: true,
      active14: true,
      active15: true,
      active16: true,
      active17: false,
    },
    {
      id: 3,
      title: "PREMIUM",
      title2: "360 Degree Marketing ",
      price: 60000,
      price2: 750,
      point1: "Initial Review & Analysis ",
      point2: "Keyword Research Upto 15",
      point3: "Website Analysis ",
      point4: "Competitor Analysis ",
      point5: "On-Page Optimization ",
      point6: "Meta Tag Optimization ",
      point7: "Alt & Title Tag Optimization",
      point8: "Backlink Analysis",
      point9: "Backlinks Creation 40",
      point10: "SEO Friendly Content Check",
      point11: "Social Media Marketing ",
      point12: "Content Creation Upto 12 Blogs ",
      point13: "Third Party Content Creation",
      point14: "Email Marketing Unlimited",
      point15: "Paid Advertising ",
      point16: "Social Media Planning ",
      point17: "Social Media Content Creation ",
      message: `Hi, My [Your Name] I'm interested in the Premium Marketing ${select} Plan. Could we schedule a meeting? Please let me know your availability for a call. `,
      active1: true,
      active2: true,
      active3: true,
      active4: true,
      active5: true,
      active6: true,
      active7: true,
      active8: true,
      active9: true,
      active10: true,
      active11: true,
      active12: true,
      active13: true,
      active14: true,
      active15: true,
      active16: true,
      active17: true,
    },
  ];

  return (
    <div className="service_list">
      <div className="web-title-1">
        <p>Find the best plan for Digital Marketing.</p>
      </div>

      <div className="plans-sectionweb plan_mb">
        {plansWeb.map((item, index) => (
          <div
            key={index}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={item.id === 1 ? "" : "200"}
            className="card-1-web"
          >
            <div className="title-2card">
              <h1>{item.title2}</h1>
            </div>
            <h1 className="card-web-h1">{item.title}</h1>

            <h1 className="plans-price">
              {select === "INR" ? (
                <Currency quantity={item?.price} currency="INR" />
              ) : (
                <Currency quantity={item?.price2} currency="USD" />
              )}
            </h1>
            <p className="package-para">{item.description}</p>

            <div className="point-offer-plan">
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active1 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point1}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active2 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point2}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active3 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point3}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active4 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point4}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active5 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point5}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active6 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point6}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active7 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point7}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active8 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point8}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active9 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point9}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active10 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point10}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active11 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point11}</h1>
              </div>

              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active12 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point12}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active13 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point13}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active14 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point14}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active15 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point15}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active16 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point16}</h1>
              </div>
              <div className="plans-check-d1">
                <div className="plans-check-d2">
                  {item.active17 ? <FaCheck /> : <FaXmark size={12} />}
                </div>

                <h1>{item.point17}</h1>
              </div>
            </div>
            {/* 
            <div>
              <ul style={{ margin: 0 }}>
                <li style={{ margin: 0, padding: 0 }} className="li-plans1">
                  <div className="list_inner2">
                    <div className="learn_more more-d1">
                      More details <span></span>
                    </div>
                  </div>
                </li>
              </ul>
            </div> */}
            <div></div>
            <div></div>

            <div className="btn-plan1">
              <ReactWhatsapp number={"+91 8968003769"} message={item.message}>
                Get started
              </ReactWhatsapp>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marketing;
