import React, { useEffect, useState } from "react";
import Webplans from "./Webplans";
import AppPlan from "./AppPlan";
import Marketing from "./Marketing";


const Plans = () => {
  // const [select, setSelect] = useState("INR");




  const [select, setSelect] = useState("USD"); // Default to USD

  useEffect(() => {
    // Get the user's location
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const country = data.country_code;
        console.log(country)
        if (country === 'IN') {
          setSelect('INR'); // Set currency to INR if user is in India
        }
      })
      .catch(error => {
        console.error('Error fetching location data:', error);
      });
  }, []);


  return (
    <div className="service_list">
      <div className=" plans-d-3">
        <div className="beny_tm_title_holder">
          <span>Plans</span>
        
          <h2>Single Payment Lifetime Access</h2>
        </div>

        <Webplans select={select} setSelect={setSelect} />
      </div>

      <AppPlan select={select} setSelect={setSelect} />
      <Marketing select={select} setSelect={setSelect} />

    </div>
  );
};

export default Plans;
