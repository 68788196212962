import React from "react";
import Preview from "../views/Preview";

import HomeParallaxTyper from "../views/all-home-version/HomeParallaxTyper";

import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import { Toaster } from "react-hot-toast";

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Toaster/>
      <Routes>
        {/* <Route path="/" element={<Preview />} /> */}
        {/* <Route path="/home-light-animation" element={<HomeLightAnimation />} />
        <Route path="/home-dark-animation" element={<HomeDarkAnimation />} />
        <Route path="/home-typer-creative" element={<HomeTyperCreative />} /> */}
        <Route path="/" element={<HomeParallaxTyper />} />
        <Route path="*" element={<NotFound />} />
   
      </Routes>
    </>
  );
};

export default AllRoutes;
